import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

function App() {
  // useEffect(() => {
  //   if ("serviceWorker" in navigator) {
  //     serviceWorkerRegistration.register();
  //   }
  // }, []);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{ color: "#fce205" }}>
          Editar <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          is that it?
        </a>
      </header>
    </div>
  );
}

export default App;
